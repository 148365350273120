.infoBox {
    @apply flex flex-row items-start rounded-3xl p-10 mb-20 gap-20;
    background-color: #1B1D2C;
}

.title {
    @apply text-4xl font-semibold text-transparent bg-clip-text bg-gradient-to-r from-fuchsia-600 to-blue-700 pb-6;
}

.leftBlock {
    @apply flex flex-col justify-between items-center gap-3 h-full;
}

.dangerBtn {
    @apply transition-all duration-300 ease-in-out;
    background: #76071B;

    &:hover {
        background: #ad0024;
    }
}

.ava {
    @apply rounded-2xl h-44 w-44;
}

.userInfo {
    @apply w-1/3 flex flex-col gap-2;
}

.nameBox {
    @apply flex flex-row justify-between items-center;
}

.aboutEditBox {
    @apply flex flex-row justify-between;
}

.name {
    @apply text-xl font-semibold;
}

.edit {
    @apply h-6 w-6 flex-none;
}

.about {
    @apply text-gray-400;
}

.aboutBox {
    @apply pb-4;
}

.hackathonsInfo {
    @apply w-1/3 flex flex-col gap-2;
}

.btn {
    @apply py-3 px-8 w-fit rounded-md text-xl flex items-center align-middle justify-center text-white bg-[#13276B]
}