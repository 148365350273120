.back {
    @apply w-screen min-h-screen;
}

.logoBox{
    @apply flex flex-row gap-4 items-center justify-center pt-10;
}

.logo {
    @apply h-7 w-7;
}

.logoTitle{
    @apply text-2xl;
    font-family: Karla, serif;
}

.backImg{
    @apply absolute right-0 top-0 w-72;
}

.backImg2{
    @apply absolute bottom-0 left-0 w-80;
}

.backImg3{
    @apply absolute h-10 w-10 left-32 bottom-[370px];
}

.backImg4{
    @apply absolute h-3 w-3 right-80 top-36;
}

.backImg5{
    @apply absolute h-24 right-[450px] bottom-32;
}

.backImg6{
    @apply absolute h-36 left-[400px] top-20 z-20;
}