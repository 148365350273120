.box{
    @apply flex justify-center container mx-auto my-auto p-10;
}

.formBox{
    @apply flex flex-col gap-4 backdrop-blur-md backdrop-filter bg-opacity-20 rounded-3xl p-8 z-10 w-[700px];
    background: linear-gradient(to bottom right, rgba(202, 50, 255, 0.09), rgba(8, 5, 139, 0.17));
}

.formBox h3 {
    @apply text-4xl text-center font-semibold text-transparent bg-clip-text bg-gradient-to-r from-fuchsia-600 to-blue-700 pb-6;
}

.form {
    @apply flex flex-col gap-3 w-5/6 mx-auto;
}

/*.form input {*/
/*    @apply py-3 px-6 rounded-lg text-xl focus:outline-none;*/
/*    background-color: #0A0A18;*/
/*}*/

.btnBox {
    @apply flex justify-center mt-8 mb-4;
}

.linkBox {
    @apply flex flex-row gap-8 justify-center text-xl;
}

.link{
    @apply underline;
}