.box {
    @apply flex flex-row gap-6;
}

.leftBox {
    @apply w-[700px] h-screen flex flex-col gap-6;
}

.rightBox {
    @apply w-full;
}

.hackBox {
    @apply flex flex-col gap-4 rounded-2xl px-8 py-6;
    background-color: #0E0E21;
}

.boxTitle {
    @apply text-gray-400 text-center text-lg;
}

.textBox {
    @apply flex flex-col gap-2 mb-4;
}

.title {
    @apply font-semibold text-lg;
}

.type {
    @apply text-gray-400 font-light;
}

.descBox {
    @apply flex flex-row gap-2 items-end;
}

.descTitle {
    @apply text-gray-400 font-light;
}

.descText {
    @apply font-semibold text-lg flex-auto;
}

.btnBox {
    @apply flex justify-center;
}

.editBox {
    @apply flex flex-row justify-between items-start gap-10;
}

.edit {
    @apply h-6 w-6 flex-none;
}

.teamBox {
    @apply flex flex-col gap-6 py-2;
}

.btnInvite {
    @apply py-3 px-8 w-full rounded-md text-xl flex items-center align-middle justify-center text-white;
    background: #13276B;
}

.btnReady {
    @apply py-3 px-8 w-full rounded-md text-xl flex items-center align-middle justify-center text-white;
    background: #136B31;
}

.btnDelete {
    @apply py-3 px-8 w-full rounded-md text-xl flex items-center align-middle justify-center text-white;
    background: #6B1313;
}