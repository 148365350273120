.container {
    @apply min-h-screen;
}

.container h2 {
    @apply text-3xl font-semibold mb-6;
}

.disabledButton {
    @apply disabled;
    display: none;
}