.box{
    @apply flex justify-center items-center container mx-auto my-auto p-10;
}

.formBox{
    @apply flex flex-col gap-4 backdrop-blur-md backdrop-filter bg-opacity-20 rounded-3xl p-14 z-10 w-[600px] h-auto absolute top-1/3;
    background: linear-gradient(to bottom right, rgba(202, 50, 255, 0.09), rgba(8, 5, 139, 0.17));
}

.formBox h2{
    @apply text-3xl text-center pb-4;
}

.formBox p{
    @apply text-xl;
}