.backImg {
    @apply absolute top-0 h-1/2 w-screen object-cover object-top;
}

.titleBox {
    @apply flex justify-center py-28;
}

.titleBox h1 {
    @apply text-center w-3/4 text-6xl font-semibold z-10;
    font-family: Montserrat, serif;
}

.title {
    @apply text-center text-4xl font-semibold pb-14;
}
