.box{
    @apply pb-10 ;
}

.date{
    @apply text-2xl font-semibold pl-24 ml-2 pb-6;
    font-family: Montserrat, serif;
    color: #2357a4;
}

.dateDescription{
    @apply text-2xl flex flex-col gap-2;
}