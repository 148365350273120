.preview {
    @apply h-screen container mx-auto;
}

.previewText {
    @apply px-44 pt-10;
}

.previewText .year {
    @apply text-7xl font-bold pb-6 relative z-10;
    font-family: Montserrat, serif;
}

.previewText h1 {
    @apply text-6xl font-bold w-3/4 pt-10 pb-6 leading-tight relative z-10;
    font-family: Montserrat, serif;
}

.previewText p {
    @apply text-2xl w-2/3 relative z-10;
}

.previewText .data {
    @apply text-blue-400 py-10 relative z-10;
}

.backCircle{
    @apply absolute blur-3xl h-80 w-80 rounded-full top-44 left-20 z-0;
    background-color: #222b8c;
}

.backCircle2{
    @apply absolute blur-3xl h-72 w-72 rounded-full top-96 left-44 z-0;
    background-color: #2c3285;
}

.backCircle3{
    @apply absolute blur-3xl h-60 w-60 rounded-full top-96 right-0 z-0;
    background-color: #353b86;
}

.backCircle4{
    @apply absolute blur-3xl h-60 w-60 rounded-full top-[500px] right-28 z-0;
    background-color: #333669;
}

.box {
    @apply pt-32 px-44 container mx-auto;
}

.textBox{
    @apply pt-12 pb-28;
}

.titleBox{
    @apply flex flex-row gap-x-16 items-center pb-16;
    font-family: Montserrat, serif;
}

.circle{
    @apply h-10 w-10 blur-lg;
    background-color: #3852DF;
}

.titleBox h2{
    @apply text-4xl font-semibold;
    font-family: Montserrat, serif;
}

.textBox p{
    @apply text-xl font-semibold w-5/6;
}

.countBox{
    @apply w-2/3 absolute left-0 py-6 pl-64 text-2xl;
    background: linear-gradient(to right, #1B437F, #0A0A18);
}

.teamBox{
    @apply flex flex-col justify-between pt-56 pb-24;
}

.teamBox h2{
    @apply text-6xl font-semibold;
    font-family: Montserrat, serif;
}

.createTeamBox{
    @apply flex flex-row justify-between py-10;
}

.createTeamBox p{
    @apply text-2xl w-1/3;
}

.deadlinesBox{
    @apply my-6 flex flex-row gap-8;
}