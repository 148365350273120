.box{
    @apply flex flex-row items-center;
}

.dash{
    @apply h-0.5 w-full rounded-full;
    background-color: rgb(107,114,128);
}

.text{
    @apply text-xl text-gray-500 px-6 whitespace-nowrap;
}