.box{
    @apply w-[400px] rounded-3xl shadow-lg hover:shadow-2xl hover:-translate-x-3 hover:-translate-y-3 transition-all duration-300 ease-in-out;
    background-color: #1B1D2C;
}

.img{
    @apply rounded-t-3xl;
}

.textBox{
    @apply p-6 flex flex-col gap-2;
}

.title{
    @apply font-semibold text-xl;
}

.type{
    @apply text-gray-400 font-light;
}

.registerBox{
    @apply flex flex-row gap-2 items-end;
}

.register{
    @apply text-gray-400 font-light;
}

.data{
    @apply font-semibold text-xl;
}