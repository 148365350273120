.footerBox {
    @apply mt-20 py-14 px-20;
    background-color: #0E0E21;
}

.box {
    @apply container mx-auto flex flex-row;
}

.logoBox{
    @apply flex flex-col gap-4 w-1/3;
}

.logoBox img {
    @apply w-16 h-16 mb-6;
}

.linkBox {
    @apply flex flex-row w-2/3 justify-between pr-10;
}

.linkBox_1 {
    @apply flex flex-col gap-4;
}

.linkBox_1 h4{
    @apply text-lg text-gray-400 uppercase;
}

.link {
    @apply underline;
}