@import url('https://fonts.googleapis.com/css2?family=Nunito:wght@100;200;300;400;500;600;700;800;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Karla:wght@400;500;600;700;800&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;600;700;800&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;

/*@font-family: 'Nunito', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial,*/
/*'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol',*/
/*'Noto Color Emoji';*/

body {
    margin: 0;
    font-family: 'Nunito', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background-color: #0A0A18;
    color: #fff;
}

html {
    scroll-behavior: smooth;
}

@font-face {
    font-family: 'Floripa';
    src: local('Floripa'),
    url("../src/fonts/Floripa.ttf") format('truetype');
}



@font-face {
    font-family: 'Soyuz Grotesk Bold';
    src: local('Soyuz'),
    url("../src/fonts/Soyuz Grotesk Bold.otf") format('truetype');
}

/*.btn {*/
/*    @apply py-3 px-8 w-full rounded-md text-xl flex items-center align-middle justify-center text-white;*/
/*    background: linear-gradient(to bottom right, #591365 10%, #13276B);*/
/*}*/

.ant-btn {
    @apply !border-0 !py-5 !px-6 !rounded-md !text-xl flex items-center align-middle justify-center text-white
    hover:bg-opacity-90 hover:!text-gray-50;;
    background: linear-gradient(to bottom right, #591365 10%, #13276B);
}

.ant-form-item-label {
    @apply text-lg font-semibold;
}

.ant-input {
    @apply !py-2 !px-6 !rounded-lg !text-xl;
    background-color: #0A0A18;
}

.ant-input-password {
    @apply !py-2 !px-4;
    background-color: #0A0A18;
}

.ant-input-password > .ant-input {
    @apply !py-0 !px-2;
}

.ant-modal-footer button {
    @apply inline !py-0 !px-3 !mt-2;
}