.container {
    @apply min-h-screen;
}

.container h2 {
    @apply text-3xl font-semibold mb-6;
}

.activeBox {
    @apply flex flex-row gap-6 items-center;
}

.activeImg {
    @apply h-6 w-6 opacity-60;
}

.editImg {
    @apply h-6 w-6;
}

.containerHeader {
    @apply flex flex-row justify-between;
}
