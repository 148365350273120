.preview{
    @apply flex flex-col h-screen items-center justify-center relative;
}

.previewImg {
    @apply object-cover object-center absolute block h-screen w-screen ;
}

.title {
    @apply text-xl sm:text-6xl text-center uppercase flex items-center justify-center z-10;
    font-family: Floripa;
}

.description{
    @apply text-4xl flex items-center justify-center mt-14 z-10 w-1/2;
}

.inviteBox{
    @apply container mx-auto pb-32;
}

.headerBox{
    @apply absolute top-6;
}

.box {
    @apply flex flex-col justify-center container mx-auto px-24;
}

.cardBox {
    @apply flex flex-row gap-8;
}

.line1{
    @apply w-1 h-[300px] ml-9;
    background: linear-gradient(to bottom, #250935, #6602B6, #02089D, #02032B);
}

.line2{
    @apply w-1 h-[100px] ml-[116px];
    background: linear-gradient(to bottom, #02032B, #02089D, #02032B);
}

.line3{
    @apply w-1 h-[300px] ml-9;
    background: linear-gradient(to bottom, #02032B, #02089D, #6602B6, #250935);
}

.formBox{
    @apply flex flex-col gap-4 backdrop-blur-md backdrop-filter bg-opacity-20 rounded-xl p-10 z-10;
    background: linear-gradient(to bottom right, rgba(202, 50, 255, 0.09), rgba(8, 5, 139, 0.17));
}

.formBox h3 {
    @apply text-5xl text-center font-semibold text-transparent bg-clip-text bg-gradient-to-r from-fuchsia-600 to-blue-700 pb-10;
}

.form {
    @apply flex flex-col gap-4 w-3/4 mx-auto;
}

.form input {
    @apply py-4 px-6 rounded-lg text-xl focus:outline-none;
    background-color: #0A0A18;
}

.btnBox {
    @apply flex justify-center mt-8 mb-4;
}

.linkBox {
   @apply flex flex-row gap-8 justify-center text-xl;
}

.link{
    @apply underline;
}

.backImg {
    @apply absolute right-0 -translate-y-1/2;
}

.backImg2 {
    @apply absolute h-10 w-10 right-72 -translate-y-72;
}

.backImg3{
    @apply absolute right-[500px] -translate-y-28;
}

.backImg4{
    @apply absolute left-24 h-3 w-3 -translate-y-56;
}

.backImg5{
    @apply absolute left-0 translate-y-44;
}

.backImg6{
    @apply absolute h-10 w-10 right-36 -translate-y-80;
}

.backImg7{
    @apply absolute h-5 w-5 translate-x-72 -translate-y-32;
}

.backImg8{
    @apply absolute left-0 translate-y-44 h-[560px];
}

.backImg9 {
    @apply absolute h-10 w-10 right-96 translate-y-96;
}

.backImg10 {
    @apply absolute h-96 right-0 translate-y-[450px];
}

.backImg11 {
    @apply absolute left-10 translate-y-[850px] z-20;
}

.backImg12 {
    @apply absolute h-28 w-28 right-32 translate-y-[1200px];
}

.robot {
    @apply absolute right-36;
}