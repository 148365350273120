.box {
    @apply flex flex-col pl-20;
}

.titleBox {
    @apply flex flex-row items-center gap-10;
}

.titleBox h2 {
    @apply text-5xl;
    font-family: Soyuz Grotesk Bold, serif;
}

.descriptionBox {
    @apply flex flex-row gap-20;
}

.descriptionBox p {
    @apply text-xl w-1/2 pt-8;
}