.box{
    @apply flex flex-col rounded-2xl;
    background-color: #0A0A18;
}

.mainBox{
    @apply flex flex-row gap-6 p-6;
}

.ava{
    @apply w-36 h-36;
}

.mainInfoBox{
    @apply flex flex-row gap-6 items-center;
}

.mainInfo{
    @apply text-lg font-semibold items-end;
}

.textBox{
    @apply flex flex-col gap-2 pr-4;
}

.descTitle {
    @apply text-gray-400 font-light;
}

.descText {
    @apply flex-auto font-light;
}

.star{
    @apply w-5 h-5 mb-1;
}

.btn{
    @apply text-xl rounded-b-2xl py-4;
    background-color: #76071B;
}