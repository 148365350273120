.glass{
    @apply bg-gray-500 rounded-xl bg-clip-padding backdrop-filter backdrop-blur-md bg-opacity-20 border border-gray-700 p-8 relative z-10 flex flex-col justify-between;
    width: 420px;
}

.titleBox{
    @apply flex flex-row items-center gap-4 mb-4;
}

.title {
    @apply text-3xl;
}

.glass img {
    @apply w-10 h-10;
}

.description {
    @apply mb-6;
}

