.box{
    @apply p-10 pt-10 rounded-2xl border-4 relative w-1/4;
    background: linear-gradient(#0A0A18, #0A0A18) padding-box,
    linear-gradient(to bottom, #1B2A75, #4C0A74) border-box;
    border: solid transparent;
}

.data{
    @apply text-2xl absolute -top-4 px-4 font-semibold;
    font-family: Montserrat, serif;
    background-color: #0A0A18;
    color: #2357a4;
}

.text{
    @apply text-2xl;
}
