.existingTeam {
    @apply w-full py-4 px-3 rounded-lg mb-5 shadow-lg hover:shadow-2xl hover:-translate-x-1 hover:-translate-y-1 transition-all duration-300 ease-in-out
        flex flex-row justify-between items-center cursor-pointer;
    ;
    background-color: #1B1D2C;
}

.existingTeamTitle {
    @apply text-2xl font-semibold;
}

.box {
    @apply flex flex-row justify-center items-start gap-20;
}

.teamBox {
    @apply w-[500px] flex flex-col justify-between gap-6;
}

.inputTitle {
    @apply text-lg;
}

.input {
    @apply w-full rounded-xl border border-gray-800 p-4 focus:outline-none;
    background-color: #0E0E21;
}

.descInput {
    @apply h-44;
}

.orTitleBox {
    @apply text-2xl text-gray-500 flex items-center h-[500px];
}

.teamDesc {
    @apply py-6;
}