.header {
    @apply flex flex-row items-center justify-between w-full text-white mx-20 py-6 z-10;
}

.headerBox {
    @apply items-center justify-center hidden sm:flex ;
}

.logo {
    @apply w-8 h-8;
}

.logoTitle {
    @apply text-2xl font-medium;
    font-family: Karla, serif;
}

.logoBox {
    @apply flex flex-row align-middle gap-2 items-center;
}

.pageTitleBox {
    @apply flex flex-row items-center gap-20 text-xl;
}