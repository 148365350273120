.glass{
    @apply bg-gray-500 rounded-xl bg-clip-padding backdrop-filter backdrop-blur-md bg-opacity-20 border border-gray-700 w-1/2 p-8 ml-40 relative ;
}

.text {
    @apply text-white text-xl mb-6;
}

/* animation */
@keyframes move {
    50% {
        transform: translate(280px, -220px);
    }
}

.robot {
    @apply absolute -right-32 transform translate-x-1/2 -translate-y-3/4 pl-20;
    animation: move 4s linear infinite;
}


