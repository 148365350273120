.preview {
    @apply h-screen container mx-auto;
}

.title {
    @apply text-3xl font-semibold;
    font-family: Montserrat, serif;
}

.timerBox {
    @apply flex flex-row gap-44 h-[500px] items-center justify-between;
}

.time {
    @apply flex flex-row items-end gap-1 relative z-10;
}

.timeNumber {
    @apply font-bold;
    font-size: 80px !important;
    line-height: 1;
    font-family: Montserrat, serif;
}

.timeNumber div {
    font-size: 80px !important;
    font-family: Montserrat, serif !important;
}

.timeText {
    @apply text-3xl pr-2 relative z-10;
}

.timer {
    @apply flex flex-col gap-4;
}

.backCircle {
    @apply absolute blur-3xl h-80 w-80 rounded-full top-44 left-20 z-0;
    background-color: #222b8c;
}

.backCircle2 {
    @apply absolute blur-3xl h-72 w-72 rounded-full top-96 left-44 z-0;
    background-color: #2c3285;
}

.backCircle3 {
    @apply absolute blur-3xl h-60 w-60 rounded-full top-96 right-0 z-0;
    background-color: #353b86;
}

.backCircle4 {
    @apply absolute blur-3xl h-60 w-60 rounded-full top-[500px] right-28 z-0;
    background-color: #333669;
}

.textBox {
    @apply pt-12 pb-28;
}

.titleBox {
    @apply flex flex-row gap-x-16 items-center pb-16;
    font-family: Montserrat, serif;
}

.circle {
    @apply h-10 w-10 blur-lg;
    background-color: #3852DF;
}


.titleBox h2 {
    @apply text-4xl font-semibold;
    font-family: Montserrat, serif;
}

.textBox p {
    @apply text-xl font-semibold w-5/6 pt-4;
}

.textTitle {
    @apply text-xl font-semibold w-5/6 py-4;
}

ul {
    @apply pl-10;
    list-style-type: circle;
    color: white;
}

li {
    @apply text-xl font-semibold w-5/6 pb-2;
}

.inputTitle {
    @apply text-2xl font-medium;
}

.input {
    @apply w-full rounded-xl border border-gray-800 p-4 focus:outline-none h-64;
    background-color: #0E0E21;
}

.btnBox {
    @apply flex justify-center mt-6;
}
